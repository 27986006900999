/* eslint-disable */
/** 
* This file contains language specific message functions for tree-shaking. 
* 
*! WARNING: Only import messages from this file if you want to manually
*! optimize your bundle. Else, import from the `messages.js` file. 
* 
* Your bundler will (in the future) automatically replace the index function 
* with a language specific message function in the build step. 
*/
/**
 * @param {{ compare: NonNullable<unknown>, ethereumVsBitcoin: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const home_compare_pages_screenshot_subtitle = (params) => `Just go to <a href='${params.compare}'>Compare</a> and pick your favorite blockchains for comparison! For example, here is <a href='${params.ethereumVsBitcoin}'>Ethereum vs Bitcoin</a>.`
