/* eslint-disable */
/** 
* This file contains language specific message functions for tree-shaking. 
* 
*! WARNING: Only import messages from this file if you want to manually
*! optimize your bundle. Else, import from the `messages.js` file. 
* 
* Your bundler will (in the future) automatically replace the index function 
* with a language specific message function in the build step. 
*/
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const home_chain_pages_description = () => `At Chainspect, we try to provide the best possible experience to our users. So, if you are only interested in a couple of blockchains, you can access their metrics on their individual chain pages. <br /> Each chain page includes all our metrics: TPS, Max Recorded TPS, Max Theoretical TPS, Finality, Block Time, and Governance Model, as well as a concise description of the blockchain, popular comparisons for it, and social media links.`
