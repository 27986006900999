/* eslint-disable */
/** 
* This file contains language specific message functions for tree-shaking. 
* 
*! WARNING: Only import messages from this file if you want to manually
*! optimize your bundle. Else, import from the `messages.js` file. 
* 
* Your bundler will (in the future) automatically replace the index function 
* with a language specific message function in the build step. 
*/
/**
 * @param {{ ethereum: NonNullable<unknown>, bitcoin: NonNullable<unknown>, solana: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const home_hero_description = (params) => `Chainspect is a blockchain analytics platform focused on technical metrics for <a href='${params.ethereum}'>Ethereum</a>, <a href='${params.bitcoin}'>Bitcoin</a>, <a href='${params.solana}'>Solana</a>, and other blockchains.`
