/* eslint-disable */
/** 
* This file contains language specific message functions for tree-shaking. 
* 
*! WARNING: Only import messages from this file if you want to manually
*! optimize your bundle. Else, import from the `messages.js` file. 
* 
* Your bundler will (in the future) automatically replace the index function 
* with a language specific message function in the build step. 
*/
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const home_compare_pages_description = () => `We strive to simplify the process of comparing blockchains by offering our unique tool to compare any two blockchains we track. <br /> The comparison includes all our metrics: TPS, Max Recorded TPS, Max Theoretical TPS, Finality, Block Time, and Governance Model, and allows you to quickly identify which blockchain is the best in each metric and see how big the gap is.`
